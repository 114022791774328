<template>
  <div class="hlw-process">
    <h6>体验流程</h6>
    <div>
        <div>
            <img src="@/assets/img/hlwicon1.png"/>
            <span><img src="@/assets/img/sbcj.png"/></span>
            <p>设备创建</p>
        </div>
        <div>
            <img src="@/assets/img/hlwicon1.png"/>
            <span><img src="@/assets/img/cxsl.png"/></span>
            <p>程序烧录</p>
        </div>
        <div>
            <img src="@/assets/img/hlwicon1.png"/>
            <span><img src="@/assets/img/sbjr.png"/></span>
            <p>设备接入</p>
        </div>
        <div>
            <img src="@/assets/img/hlwicon1.png"/>
            <span><img src="@/assets/img/sjcj.png"/></span>
            <p>数据采集</p>
        </div>
        <div>
            <img src="@/assets/img/hlwicon1.png"/>
            <span><img src="@/assets/img/sjcx.png"/></span>
            <p>数据呈现</p>
        </div>
        <div>
            <span><img src="@/assets/img/yydj.png"/></span>
            <p>应用搭建</p>
        </div>
    </div>
    <a-button class="btn-red udesk" size="large">加入合作</a-button>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.hlw-process{
    background: url(../../assets/img/hlwtylcbg.png) no-repeat;
    background-size: 100% 100%;
    padding: 70px 0 98px;
    >h6{
        font-size: 32px;
        font-weight: bold;
        color: rgba(0,0,0,0.85);
        line-height: 45px;
        text-align: center;
        margin-bottom: 60px;
    }
    >div{
        width: 1280px;
        margin: 0 auto;
        padding: 0 40px;
        display: flex;
        justify-content: space-between;
        >div{
            position: relative;
            >img{
                position: absolute;
                right: -98px;
                top: 50px;
            }
            >span{
                display: block;
                >img{
                    display: block;
                }
            }
            >p{
                text-align: center;
                font-size: 16px;
                color: rgba(0,0,0,0.85);
                line-height: 22px;
            }
        }
    }
    >button{
        display: block;
        width: 128px;
        margin: 60px auto 0;
    }
}
</style>
