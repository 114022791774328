<template>
  <div class="market-box">
    <div class="market-con">
      <h6>工业应用市场</h6>
      <p>智能制造一体化、数字化、智能化、服务及产品</p>
      <swiper
        ref="mySwiper"
        :options="swiperOptions"
        class="swiper-no-swiping swiper-box"
      >
        <swiper-slide>
          <div class="swiper-item">
            <img src="@/assets/img/marketBox/swiper1.png" />
            <div class="item-right">
              <div class="title">质量追踪管理系统</div>
              <div class="tag">
                <span>质量管理</span>
                <span>数据采集</span>
              </div>
              <p>
                建设移动化质量追踪管理系统，⽣产过程检验将实现：可知：业务数据采集,
                实现质量⼯作状况的“可知”；可控：质量管理流程优化，
                实现质量过程管理的“可控”；可管：记录并跟踪质量问题的整改，实现质量管理的“可管”。
              </p>
              <a-button class="btn-red item-btn" @click="handleClickViewAll"
                >查看更多应用</a-button
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item">
            <img src="@/assets/img/marketBox/swiper2.png" />
            <div class="item-right">
              <div class="title">设备安全与可靠性管理系统</div>
              <div class="tag">
                <span>实时诊断</span>
                <span>智能分析</span>
              </div>
              <p>
                系统通过云原⽣基础底座和⾯向数字孪⽣的设计，解决了各系统信息孤岛、数据离散、数据⽆法融合和数据展⽰形式单⼀等问题，实现了消除数据孤岛，发挥数据价值。同时通过对设备进⾏故障预测性分析与诊断，提⾼了⽣产安全性和决策效率。
              </p>
              <a-button class="btn-red item-btn" @click="handleClickViewAll"
                >查看更多应用</a-button
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item">
            <img src="@/assets/img/marketBox/swiper3.png" />
            <div class="item-right">
              <div class="title">基于IoT新能源车辆租赁平台</div>
              <div class="tag">
                <span>新能源车辆</span>
                <span>车辆租赁</span>
              </div>
              <p>
                平台通过IoT数据与传统租赁模式的结合属于新技术与传统业务的结合，实现设备运⾏数据到租赁业务上的赋能，打造开放、共享的“互联⽹＋新能源”产业⽣态，引领和推动新能源汽车⾏业转型升级。
              </p>
              <a-button class="btn-red item-btn" @click="handleClickViewAll"
                >查看更多应用</a-button
              >
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="swiper-item">
            <img src="@/assets/img/marketBox/swiper4.png" />
            <div class="item-right">
              <div class="title">基于数据挖掘技术的⽯化⾏业⼤数据平台</div>
              <div class="tag">
                <span>石化行业</span>
                <span>数据挖掘</span>
              </div>
              <p>
                以Hadoop技术为基础构建⼤数据基础平台，为海量数据的承载提供⽀撑。平台帮助装置操作⼈员提供装置特征信息显性知识输出，构建了知识积累平台，降低对⼯艺专家的依赖度。为装置操作⼈员提供装置特征信息显性知识输出，构建了知识积累平台，降低对⼯艺专家的依赖度。
              </p>
              <a-button class="btn-red item-btn" @click="handleClickViewAll"
                >查看更多应用</a-button
              >
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>

      <div class="pagination-box">
        <div @click="slideTo11(0)">
          <a-progress
            :percent="num1"
            size="small"
            strokeColor="#EA0B06"
            :show-info="false"
          />
        </div>
        <div @click="slideTo11(1)">
          <a-progress
            :percent="num2"
            size="small"
            strokeColor="#EA0B06"
            :show-info="false"
          />
        </div>
        <div @click="slideTo11(2)">
          <a-progress
            :percent="num3"
            size="small"
            strokeColor="#EA0B06"
            :show-info="false"
          />
        </div>
        <div @click="slideTo11(3)">
          <a-progress
            :percent="num4"
            size="small"
            strokeColor="#EA0B06"
            :show-info="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let vm;
let time1;
let time2;
let time3;
let time4;
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "market",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      num1: 0,
      num2: 0,
      num3: 0,
      num4: 0,
      numActive: 1,
      swiperOptions: {
        loop: true,
        speed: 2000,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "custom",
          renderCustom: function (swiper, current, total) {},
        },
        on: {
          slideChangeTransitionEnd: function (swiper) {
            console.log(this.realIndex, "swiper==-============");
            vm.swiperChange(this.realIndex);
          },
        },
      },
    };
  },
  created() {
    vm = this;
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  watch: {
    numActive(num) {
      // this.swiperChange(num)
    },
  },

  methods: {
    handleClickViewAll() {
      this.$router.push({
        path: "/market",
      });
    },

    swiperChange(num) {
      if (num === 0) {
        if (time4) window.clearInterval(time4);
        time4 = null;
        this.num4 = 0;
        time1 = window.setInterval(() => {
          this.num1 += 1;
        }, 50);
      }
      if (num === 1) {
        window.clearInterval(time1);
        time1 = null;
        this.num1 = 0;
        time2 = window.setInterval(() => {
          this.num2 += 1;
        }, 50);
      }
      if (num === 2) {
        window.clearInterval(time2);
        time2 = null;
        this.num2 = 0;
        time3 = window.setInterval(() => {
          this.num3 += 1;
        }, 50);
      }
      if (num === 3) {
        window.clearInterval(time3);
        time3 = null;
        this.num3 = 0;
        time4 = window.setInterval(() => {
          this.num4 += 1;
        }, 50);
      }
    },

    slideTo11(i) {
      this.swiper.slideToLoop(i, 0, true);
      if (i === 0) {
        this.num2 = 0;
        this.num3 = 0;
        this.num4 = 0;
        window.clearInterval(time2);
        window.clearInterval(time3);
        window.clearInterval(time4);
      }
      if (i === 1) {
        this.num1 = 0;
        this.num3 = 0;
        this.num4 = 0;
        window.clearInterval(time1);
        window.clearInterval(time3);
        window.clearInterval(time4);
      }
      if (i === 2) {
        this.num1 = 0;
        this.num2 = 0;
        this.num4 = 0;
        window.clearInterval(time1);
        window.clearInterval(time2);
        window.clearInterval(time4);
      }
      if (i === 3) {
        this.num1 = 0;
        this.num2 = 0;
        this.num3 = 0;
        window.clearInterval(time1);
        window.clearInterval(time2);
        window.clearInterval(time3);
      }
    },
  },

  mounted() {},
};
</script>

<style scoped lang="scss">
.market-box {
  width: 100%;
  height: 692px;
  background: url("../../assets/img/homeMarketBg.png") no-repeat;
  background-size: cover;
}
.market-con {
  width: 1280px;
  height: 100%;
  margin: 0 auto;
  padding: 70px 0 0;
  &::v-deep {
    .swiper-container {
      box-shadow: 0px 4px 13px 0px rgba(195, 195, 195, 0.3);
    }
  }
  > h6 {
    font-size: 32px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
    margin-bottom: 24px;
  }
  > p {
    font-size: 18px;
    color: rgba(102, 102, 102, 0.85);
    line-height: 25px;
    text-align: center;
    margin-bottom: 56px;
  }
  .swiper-item {
    padding: 8px;
    background: #ffffff;
    display: flex;
    > .item-right {
      padding: 32px 32px 0 40px;
      position: relative;
      > .title {
        font-size: 28px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
        margin-bottom: 16px;
      }
      > .tag {
        margin-bottom: 24px;
        > span {
          height: 18px;
          padding: 0 4px;
          border: 1px solid #d1d8e4;
          border-radius: 2px;
          font-size: 12px;
          color: #62697d;
          line-height: 16px;
          margin-right: 8px;
        }
      }
      > p {
        font-size: 16px;
        color: rgba(102, 102, 102, 0.85);
        line-height: 22px;
      }
      > .item-btn {
        position: absolute;
        left: 40px;
        bottom: 32px;
      }
    }
  }

  .pagination-box {
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    /* >div{
        width: 64px;
        height: 4px;
        background: #EEEFEF;
      }
     */
    > div {
      cursor: pointer;
    }
    > :not(:last-child) {
      margin-right: 8px;
    }
    &::v-deep {
      .ant-progress {
        width: 64px;
        //margin-right: 8px;
      }
      .ant-progress-inner {
        height: 4px;
        border-radius: 0;
        background: #eeefef;
      }
      .ant-progress-bg {
        border-radius: 0 !important;
      }
    }
  }

  .swiper-box {
    &::v-deep {
      .swiper-wrapper {
      }
      .swiper-slide {
        //box-shadow: 0px 4px 13px 0px rgba(195,195,195,0.3);
      }
    }
  }

  .swiper-pagination {
    /*   position: static;
      margin-top: 32px;*/
    //bottom: -40px;
    //z-index: 1000;
  }
}
</style>
