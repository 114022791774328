<template>
  <div class="hlw-product-service">
    <h6>AloT产品及服务</h6>
    <p>用户可通过AIoT产品及服务，快速定制AIoT平台及对应的行业应用</p>
    <div>
        <div :class="{'active':serviceIndex == 1}" @mousemove="serviceIndex = 1">
            <div>
                <p>
                    <img src="@/assets/img/hyyun.png">
                    <span>鸿易云</span>
                </p>
                <div>基于原生基础技术能力为物联网行业的应用部署提供一个全托管、免运维、高弹性的数字化底座。</div>
            </div>
        </div>
        <div :class="{'active':serviceIndex == 2}" @mousemove="serviceIndex = 2">
            <div>
                <p>
                    <img src="@/assets/img/hyl.png">
                    <span>鸿易连</span>
                </p>
                <div>基于开源鸿蒙软总线的统一协议，实现跨平台设备的互联接入和智能产品全生命周期管理服务。</div>
            </div>
        </div>
        <div :class="{'active':serviceIndex == 3}" @mousemove="serviceIndex = 3">
            <div>
                <p>
                    <img src="@/assets/img/hym.png">
                    <span>鸿易码</span>
                </p>
                <div>针对物联网场景提供的生产力工具，可覆盖各个物联网行业核心应用场景，加速典型场景应用的物联网数字化转型。</div>
            </div>
        </div>
        <div :class="{'active':serviceIndex == 4}" @mousemove="serviceIndex = 4">
            <div>
                <p>
                    <img src="@/assets/img/hys.png">
                    <span>鸿易数</span>
                </p>
                <div>融合了物联网、区块链、AI和BI工具，提供从数据采集、数据加工处理、数据存储、数据分析、数据可视化于一体的完整解决方案。</div>
            </div>
        </div>
        <div :class="{'active':serviceIndex == 5}" @mousemove="serviceIndex = 5">
            <div>
                <p>
                    <img src="@/assets/img/hyy.png">
                    <span>鸿易用</span>
                </p>
                <div>面向大、小家电和运动健康行业，覆盖上百个典型场景。通过大数据推荐算法支持典型大场景自定义应用低成本、快捷定制化服务。</div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            serviceIndex:1
        }
    },
}
</script>

<style lang="scss" scoped>
.hlw-product-service{
    padding: 70px 0;
    background: #fff;
    > h6 {
        font-size: 32px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        line-height: 45px;
        margin-bottom: 24px;
        text-align: center;
    }
    > p {
        font-size: 18px;
        color: rgba(102, 102, 102, 0.85);
        line-height: 25px;
        text-align: center;
        margin-bottom: 55px;
    }
    >div{
        width: 1280px;
        margin: 0 auto;
        display: flex;
        >div{
            width: 203px;
            height: 272px;
            margin-left: 16px;
            transition: all .25s ease;
            background-size: cover;
            background-position: center 0;
            position: relative;
            overflow: hidden;
            &:nth-child(1){
                background-image: url(../../assets/img/hlwfw1.jpg);
            }
            &:nth-child(2){
                background-image: url(../../assets/img/hlwfw2.jpg);
            }
            &:nth-child(3){
                background-image: url(../../assets/img/hlwfw3.jpg);
            }
            &:nth-child(4){
                background-image: url(../../assets/img/hlwfw4.jpg);
            }
            &:nth-child(5){
                background-image: url(../../assets/img/hlwfw5.jpg);
                margin-right: 0;
            }
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.9) 100%);
            }
            >div{
                position: relative;
                z-index: 2;
                padding-top: 155px;
                >p{
                    text-align: center;
                    >img{
                        display: block;
                        margin: 0 auto 16px;
                    }
                    >span{
                        display: block;
                        font-size: 22px;
                        color: #FFFFFF;
                        line-height: 30px;
                    }
                }
                >div{
                    margin-top: 17px;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 20px;
                    opacity: 0;
                }
            }
            &.active{
                width: 404px;
                &::before{
                    background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.95) 100%);
                }
                >div{
                    padding: 40px 32px 0;
                    >p{
                        display: flex;
                        align-items: center;
                        >img{
                            margin: 0 12px 0 0;
                        }
                        >span{
                            font-size: 24px;
                            color: #FFFFFF;
                            line-height: 32px;
                        }
                    }
                    >div{
                        opacity: 1;
                        transition-delay: .25s;
                    }
                }
            }
        }
    }
}
</style>