<template>
  <div class="hlw-product">
    <h6>产品智能化解决方案</h6>
    <p>以场景化、国产化为牵引，以生态服务为支撑，推动产品数智化升级</p>
    <div class="hlw-product-tag">
      <span :class="{ active: productType == 'iot' }" @click="productTag('iot')"
        >IoT产品</span
      >
      <span :class="{ active: productType == 'dev' }" @click="productTag('dev')"
        >鸿蒙开发板</span
      >
      <span :class="{ active: productType == 'soc' }" @click="productTag('soc')"
        >SOC</span
      >
    </div>
    <div class="hlw-product-content" @click="productEvent($event)">
      <div class="swiper-next"></div>
      <div class="swiper-prev"></div>
      <swiper ref="swiperBox" class="swiper swiper-box" :options="swiperOption">
        <swiper-slide>
          <div class="solutions-swiper-list">
            <div>
              <template v-for="(el,index) in productList">
                <div :key="el.id" v-if="index < 3" :data-type="productType" :data-id="el.id">
                  <span :data-type="productType" :data-id="el.id">
                    <img :src="el.imgUrl" :data-type="productType" :data-id="el.id">
                  </span>
                  <div :data-type="productType" :data-id="el.id">
                    <span :data-type="productType" :data-id="el.id">{{el.name}}</span>
                    <p :data-type="productType" :data-id="el.id">{{el.sceneTagName}}</p>
                  </div>
                </div>
              </template>
            </div>
            <a-button class="btn-color-red" size="large" :data-type="productType" data-id="">查看更多</a-button>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="solutions-swiper-list">
            <div>
              <template v-for="(el,index) in productList">
                <div :key="el.id" v-if="index > 2 && index < 6" :data-type="productType" :data-id="el.id">
                  <span :data-type="productType" :data-id="el.id">
                    <img :src="el.imgUrl" :data-type="productType" :data-id="el.id">
                  </span>
                  <div :data-type="productType" :data-id="el.id">
                    <span :data-type="productType" :data-id="el.id">{{el.name}}</span>
                    <p :data-type="productType" :data-id="el.id">{{el.sceneTagName}}</p>
                  </div>
                </div>
              </template>
            </div>
            <a-button class="btn-color-red" size="large" :data-type="productType" data-id="">查看更多</a-button>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="solutions-swiper-list">
            <div>
              <template v-for="(el,index) in productList">
                <div :key="el.id" v-if="index > 5" :data-type="productType" :data-id="el.id">
                  <span :data-type="productType" :data-id="el.id">
                    <img :src="el.imgUrl" :data-type="productType" :data-id="el.id">
                  </span>
                  <div :data-type="productType" :data-id="el.id">
                    <span :data-type="productType" :data-id="el.id">{{el.name}}</span>
                    <p :data-type="productType" :data-id="el.id">{{el.sceneTagName}}</p>
                  </div>
                </div>
              </template>
            </div>
            <a-button class="btn-color-red" size="large" :data-type="productType" data-id="">查看更多</a-button>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import VImg from "@/components/VImg";
import "swiper/css/swiper.css";
import { getProductList } from "@/api/iot";
import { mapGetters } from "vuex";
export default {
  components: {
    Swiper,
    SwiperSlide,
    VImg
  },
  data(){
    return{
      productType:'iot',
      productList:''
    }
  },
  computed: {
    ...mapGetters(["settings"]),
    swiperOption() {
      return {
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
      };
    },
  },
  mounted(){
    this.loadData()
  },
  methods:{
    loadData(){
      let page = {
        current: 1,
        size: 9,
      }
      let data = {
        status: "2",
        type: this.productType,
      }
      getProductList(page,data).then((res)=>{
        let data = res.records
        for (let index = 0; index < data.length; index++) {
          data[index].imgUrl = `${this.settings.OBS_PREFIX}/${data[index].coverAttmId}`
        }
        this.productList = data
      })
    },
    productTag(type){
      this.productType = type
      this.loadData()
    },
    productEvent(e){
      let id = e.target.dataset.id
      let type = e.target.dataset.type
      if(id === undefined){
        return
      }
      console.log(id,type)
      if(id != ''){
        if(type == 'iot'){
          this.$router.push({
            path: "/replace/info",
            query: { id },
          });
        }else{
          this.$router.push({
              path: `/replace/productInfo/${type}`,
              query: { id },
          });
        }
      }else{
        this.$router.push({
          name:'replace',
          params:{
            type
          }
        })
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.hlw-product {
  background: url(../../assets/img/hlwbg2.jpg) no-repeat;
  background-size: 100% 100%;
  padding: 70px 0 104px;
  > h6 {
    font-size: 32px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    margin-bottom: 24px;
    text-align: center;
  }
  > p {
    font-size: 18px;
    color: rgba(102, 102, 102, 0.85);
    line-height: 25px;
    text-align: center;
    margin-bottom: 60px;
  }
  .hlw-product-tag {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 56px;
    display: flex;
    justify-content: center;
    > span {
      width: 180px;
      height: 50px;
      border-bottom: 2px solid transparent;
      margin-right: 48px;
      text-align: center;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 25px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        border-color: #ea0b06;
      }
    }
  }
  .hlw-product-content {
    width: 1280px;
    margin: 0 auto;
    height: 480px;
    position: relative;
    .solutions-swiper-list {
      background: #fff;
      > div {
        margin-bottom: 16px;
        > div {
          padding: 24px;
          border-bottom: 1px solid rgba(195, 195, 195, 0.5);
          display: flex;
          align-items: center;
          cursor: pointer;
          &:last-child {
            border-bottom: 0;
          }
          > span {
            width: 80px;
            height: 80px;
            margin-right: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            > img {
              max-width: 100%;
              max-height: 100%;
              display: block;
            }
          }
          > div {
            flex: 1;
            > span {
              display: block;
              font-size: 18px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.85);
              line-height: 25px;
              margin-bottom: 12px;
            }
            > p {
              font-size: 14px;
              color: #666666;
              line-height: 20px;
              height: 40px;
              overflow: hidden;
            }
          }
        }
      }
      > button {
        display: none;
        width: 92%;
        margin: 0 auto;
      }
      & > * {
        transition: opacity 0.3s ease-out;
        opacity: 0.6;
      }
      &:hover {
        & > * {
          opacity: 1;
        }
      }
    }
    .swiper-next,
    .swiper-prev {
      position: absolute;
      z-index: 2;
      cursor: pointer;
      top: 0;
      width: 426px;
      height: 474px;
      background: linear-gradient(
        90deg,
        #f8fafc 0%,
        rgba(248, 250, 252, 0) 100%
      );
      &:hover ~ .swiper-box .solutions-swiper-list > * {
        opacity: 1;
      }
    }
    .swiper-prev {
      right: 0;
      background: linear-gradient(
        90deg,
        rgba(248, 250, 252, 0) 0%,
        #f8fafc 100%
      );
    }
  }
}
</style>
<style>
.hlw-product-content .swiper-container {
  width: 100%;
  height: 100%;
}
.hlw-product-content .swiper-slide {
  transition: 300ms;
  transform: scale(0.8);
  height: auto;
}
.hlw-product-content .swiper-wrapper {
  align-items: center;
}
.hlw-product-content .swiper-slide-active,
.hlw-product-content .swiper-slide-duplicate-active {
  transform: scale(1);
}
.hlw-product-content .swiper-slide-active .solutions-swiper-list {
  padding-bottom: 32px;
}
.hlw-product-content .swiper-slide-active .solutions-swiper-list > * {
  opacity: 1 !important;
}
.hlw-product-content .swiper-slide-active .solutions-swiper-list > button {
  display: block !important;
}
</style>