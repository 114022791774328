<template>
  <div class="hly-home-solution">
    <h6>好用、精选、场景化的自主创新解决方案</h6>
    <p>基于鸿蒙化改造和数字化转型方案拆解的知识图谱展示助力技改升级服务</p>
    <div class="hly-home-solution-tag">
      <span v-for="e in tagList" :key="e.id" :class="{'active':categoryId == e.id}" @click="categoryId = e.id">{{e.name}}</span>
    </div>
    <div class="hly-home-solution-box">
      <div class="hly-home-solution-list">
        <template v-for="(item, index) in solutionList">
          <div :key="index" @click="solutionInfo(item.id,item.hotType)">
            <img v-if="item.marketing == 3" src="@/assets/img/hlwhot.png" class="hly-home-bq">
            <img v-if="item.marketing == 2" src="@/assets/img/fntj.png" class="hly-home-bq">
            <v-img class="hly-home-solution-img" :attachment-id="item.solutionImg" :default-img="require('@/assets/img/cmrt.png')"/>
            <p>{{ item.name }}</p>
            <span>{{ item.solutionDiscription }}</span>
            <div class="hly-home-solution-lable" v-if="item.tag">
              <template v-for="(e,i) in item.tag">
                <span :key="i" v-if="i < 3">{{ e }}</span>
              </template>
            </div>
            <div class="hly-home-solution-info" @click="solutionInfo(item.id,item.hotType)">
              查看详情
            </div>
          </div>
        </template>
      </div>
    </div>
    <span @click="$router.push(`/solution?id=${categoryId}`)">查看全部 ></span>
  </div>
</template>

<script>
import { getSolutionList } from "@/api/solution";
import VImg from "@/components/VImg";
export default {
  components: {
    VImg,
  },
  data() {
    return {
      page: {
        current: 1,
        size: 8,
        total: 0,
      },
      tagList: [
        {
          name: "金融",
          id:'1500000000000000021'
        },
        {
          name: "党政",
          id:'1500000000000000022'
        },
        {
          name: "旅游出行",
          id:'1500000000000000013'
        },
        {
          name: "能源",
          id:'1500000000000000023'
        },
        {
          name: "公检法",
          id:'1500000000000000025'
        },
        {
          name: "工业环境保护",
          id:'1500000000000000034'
        },
        {
          name: "交通",
          id:'1500000000000000026'
        },
      ],
      categoryId: "1500000000000000021",
      solutionList: [],
    };
  },
  watch:{
    categoryId:function(e){
      this.loadGetSolutionList()
    }
  },
  mounted() {
    this.loadGetSolutionList()
  },
  methods: {
    // 解决方案列表
    loadGetSolutionList() {
      let { page,categoryId } = this;
      let data = {
        category:categoryId,
      };
      getSolutionList(page,data).then((res) => {
        let list = res.records
        list.forEach(item=>{
          if(item.tag){
            item.tag = item.tag.split(',').slice(0,3)
          }
        })
        this.solutionList = list;
      });
    },
    solutionInfo(id,type) {
      if(type == 2 || type == 3){
        this.$router.push({
          path: "/solution/info",
          query: { id },
        });
      }else if(type == 1){
        this.$router.push({
          path: "/solution/internet_things",
          query: { id },
        });
      }else if(type == 4 || type == 5){
        this.$router.push({
          path: "/solution/ordinary_program",
          query: { id },
        });
      }else if(type == 6){
        this.$router.push({
          path: "/solution/digitizing",
          query: { id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hly-home-solution {
  padding: 70px 0;
  >h6{
    font-size: 32px;
    font-weight: bold;
    color: rgba(0,0,0,0.85);
    line-height: 45px;
    margin-bottom: 24px;
    text-align: center;
  }
  > p {
    font-size: 18px;
    color: rgba(102,102,102,0.85);
    line-height: 25px;
    text-align: center;
    margin-bottom: 60px;
  }
  .hly-home-solution-tag{
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    justify-content: center;
    >span{
      height: 50px;
      width: 120px;
      text-align: center;
      margin-right: 24px;
      font-size: 18px;
      color: #262626;
      line-height: 25px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      &.active{
        color: #EA0B06;
        border-color: #EA0B06;
      }
    }
  }
  .hly-home-solution-box {
    width: 1280px;
    margin: 16px auto 0;
    .hly-home-solution-list {
      display: flex;
      flex-wrap: wrap;
      > div {
        width: 302px;
        height: 311px;
        padding: 24px;
        transition: all 0.3s;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        margin-top: 24px;
        margin-right: 24px;
        background: #fff;
        border: 1px solid transparent;
        &:nth-child(4n){
          margin-right: 0;
        }
        .hly-home-bq{
          position: absolute;
          z-index: 2;
          top: 0;
          right: 0;
          width: 51px;
          height: 51px;
        }
        .hly-home-solution-img {
          width: 125px;
          height: 70px;
          display: block;
          margin-bottom: 16px;
        }
        > p {
          font-size: 18px;
          font-weight: bold;
          color: rgba(0,0,0,0.85);
          line-height: 25px;
          margin-bottom: 12px;
        }
        > span {
          display: block;
          font-size: 12px;
          color: #666;
          line-height: 17px;
          height: 34px;
          overflow: hidden;
          margin-bottom: 16px;
        }
        .hly-home-solution-lable {
          display: flex;
          margin-bottom: 32px;
          > span {
            height: 18px;
            border: 1px solid #D1D8E4;
            border-radius: 2px;
            padding: 0 6px;
            margin-right: 4px;
            font-size: 12px;
            line-height: 16px;
            color: #62697D;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .hly-home-solution-info {
          width: 120px;
          text-align: center;
          line-height: 40px;
          color: #fff;
          cursor: pointer;
          transition: all 0.3s;
          font-size: 14px;
          color: #EA0B06;
          border: 1px solid #EA0B06;
        }
        &:hover {
          border: 1px solid #EA0B06;
          .hly-home-solution-info{
            background: #EA0B06;
            color: #fff;
          }
        }
      }
    }
  }
  > span {
    display: block;
    width: 100px;
    margin: 40px auto 0;
    cursor: pointer;
    color: #EA0B06;
    text-align: center;
  }
}
</style>
