<template>
  <div class="hly-home-activity">
    <h6>为生态服务商提供丰富、多样的开发者课程</h6>
    <p>
      为政企用户提供学习、交流、共创的自主创新生态，智能伴行数字化转型升级！
    </p>
    <div>
      <div class="hly-home-activity-ad">
        <strong>更多精选课程</strong>
        <p @click="$router.push(`/activity`)">查看全部 ></p>
      </div>
      <div class="hly-home-activity-list">
        <template v-for="item in courseList">
          <div :key="item.id" @click="detail(item)">
            <v-img :attachment-id="item.courseCoverAttmId" :default-img="require('@/assets/img/kctp.png')"/>
            <div>
                <p>{{ item.courseName }}</p>
                <span><a-icon type="clock-circle" /> {{item.publishedTime}}</span>
                <div>讲师：{{item.coursePresentecoursePresenter}}</div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { getCourseList } from "@/api/activity";
export default {
  components: {
    VImg
  },
  mounted() {
    this.loadGetCourseList();
  },
  data() {
    return {
      courseList: [],
      page: {
        current: 1,
        size: 3,
        total: 0,
      },
    };
  },
  methods: {
    loadGetCourseList() {
      let { page } = this;
      let data = {
        ascOrDesc: 'desc',
      }
      getCourseList(page, data).then((res) => {
        this.courseList = res.records
      })
    },
    detail(item) {
      this.$router.push(`/activity/courseInfo?id=${item.id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.hly-home-activity {
  background: #fff;
  padding: 70px 0;
  > h6 {
    font-size: 32px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    margin-bottom: 24px;
    text-align: center;
  }
  > p {
    font-size: 18px;
    color: rgba(102, 102, 102, 0.85);
    line-height: 25px;
    text-align: center;
  }

  > div {
    width: 1280px;
    margin: 55px auto 0;
    display: flex;
    .hly-home-activity-ad {
      width: 302px;
      height: 308px;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(195, 195, 195, 0.5);
      margin-right: 24px;
      background: url(../../assets/img/hlwkcbg.jpg) no-repeat;
      background-size: 100% 100%;
      padding: 40px 24px;
      >strong{
        display: block;
        font-size: 24px;
        font-weight: 500;
        color: #262626;
        line-height: 33px;
      }
      > p {
        margin-top: 24px;
        font-size: 16px;
        color: #EA0B06;
        line-height: 22px;
        cursor: pointer;
      }
    }
    .hly-home-activity-list {
      flex: 1;
      display: flex;
      >div{
        width: 302px;
        height: 308px;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px 0px rgba(195,195,195,0.5);
        margin-right: 24px;
        transition: all .3s;
        cursor: pointer;
        &:last-child{
            margin-right: 0;
        }
        >img{
            display: block;
            width: 100%;
            height: 170px;
        }
        >div{
            padding: 16px;
            >p{
                font-size: 16px;
                font-weight: 400;
                color: #262626;
                line-height: 22px;
                margin-bottom: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            >span{
                font-size: 12px;
                color: #8C8C8C;
                height: 16px;
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                >i{
                    margin-right: 4px;
                }
            }
            >div{
                height: 40px;
                background: #F8F8FA;
                border-radius: 4px;
                text-align: center;
                line-height: 40px;
                font-size: 14px;
                color: #EA0B06;
                cursor: pointer;
                transition: all .3s;
            }
        }
        &:hover{
            transform: translateY(-12px);
            >div{
                >div{
                    background: #EA0B06;
                    color: #fff;
                }
            }
        }
      }
    }
  }
}
</style>
