<template>
  <div class="hly-home">
    <home-banner />
    <!--    <market-box />-->
    <!--    <app-market/>-->
    <product />
    <product-service />
    <solution />
    <activity />
    <process />
    <!-- <div class="home-solution-footer" v-if="!isLogin">
      <div>
        <p>立即注册，即刻开启您的鸿联云之旅</p>
        <span @click="$router.push('/register')">立即体验</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import HomeBanner from "@/page/home/HomeBanner";
import product from "./product.vue";
import productService from "./productService.vue";
import solution from "./solution.vue";
import activity from "./activity.vue";
import process from "./process.vue";
import marketBox from "@/page/home/marketBox";
import { getNewsList } from "@/api/news";
import { mapGetters } from "vuex";

export default {
  components: {
    HomeBanner,
    // appMarket,
    product,
    productService,
    solution,
    activity,
    process,
    marketBox,
  },
  data() {
    return {
      homeAreaTagIndex: 1,
      policyList: [],
      page: {
        current: 1,
        size: 8,
        total: 0,
      },
    };
  },
  computed: mapGetters(["isLogin"]),
  mounted() {
    this.loadGetNewsList();
  },
  methods: {
    loadGetNewsList() {
      getNewsList(this.page, {}).then((res) => {
        this.policyList = res.records;
      });
    },
    typeSearch(val) {
      this.$router.push(`/searchInfo?val=${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.hly-home {
  .hly-home-gray-box {
    background: #f7f8fa;

    .hly-home-news {
      width: 1280px;
      margin: 0 auto 60px;
      padding-top: 24px;
      display: flex;
      justify-content: space-between;

      > div {
        width: 628px;
        background: #fff;
        min-height: 440px;
      }

      .hly-home-area {
        display: flex;

        .hly-home-area-tab {
          width: 200px;
          position: relative;
          background: #f0f3f8;
          overflow: hidden;

          > p {
            height: 72px;
            background: #f0f3f8;
            line-height: 72px;
            padding: 0 24px;
            font-size: 24px;
            border-bottom: 1px solid #e5e5e5;
          }

          > div {
            > span {
              display: block;
              height: 56px;
              cursor: pointer;
              padding: 0 24px;
              line-height: 56px;
              font-size: 16px;
              color: #262626;
              border-bottom: 1px solid #e5e5e5;
              transition: all 0.3s;

              &.active {
                color: #fff;
                background: linear-gradient(90deg, #ea4243 0%, #ff8b4c 100%);
              }
            }
          }

          > img {
            position: absolute;
            left: 40px;
            bottom: 16px;
          }
        }

        .hly-home-area-list {
          padding: 0 16px 16px;
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          align-content: flex-start;

          > div {
            background: #f9fafc;
            width: 120px;
            height: 118px;
            margin: 16px 16px 0 0;
            padding-top: 24px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
              background: #ffffff;
              box-shadow: 0px 4px 12px 0px rgba(0, 65, 152, 0.08);
            }

            &:nth-child(3n) {
              margin-right: 0;
            }

            > img {
              display: block;
              margin: 0 auto;
            }

            > p {
              line-height: 13px;
              text-align: center;
              margin-top: 24px;
            }
          }
        }
      }

      .hly-home-news-box {
        .hly-home-news-title {
          position: relative;
          height: 72px;
          background: #f0f3f8;
          padding: 0 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;

          > img {
            position: absolute;
            right: 0;
            top: -80px;
          }

          > p {
            font-size: 24px;
          }

          > span {
            color: #ea0b06;
            cursor: pointer;
            position: relative;
            z-index: 2;
          }
        }

        .hly-home-news-list {
          padding: 0 24px 24px;

          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            cursor: pointer;

            > p {
              display: flex;
              align-items: center;
              transition: all 0.3s;

              > em {
                width: 6px;
                height: 6px;
                background: #ea0b06;
                margin-right: 16px;
              }

              > span {
                font-size: 16px;
                line-height: 16px;
                max-width: 440px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            > span {
              color: #8c8c8c;
              line-height: 14px;
            }

            &:hover {
              > p {
                color: #ea0b06;
              }
            }
          }
        }
      }
    }
  }

  .home-solution-footer {
    background: url(../../assets/img/bg6.png) no-repeat;
    background-size: 100% 100%;
    padding: 40px 0 44px;

    > div {
      width: 1280px;
      margin: 0 auto;

      > p {
        line-height: 31px;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 29px;
        text-align: center;
      }

      > span {
        display: block;
        width: 120px;
        height: 40px;
        background: linear-gradient(90deg, #ea4243 0%, #ff8b4c 100%);
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        margin: 0 auto;
        color: #fff;
      }
    }
  }
}
</style>
