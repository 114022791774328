<template>
  <div class="hly-home-banner">
    <a-carousel autoplay :beforeChange="bannerafterChange" dots-class="hly-home-banner-dots">
      <div
        v-for="(el, index) in advertise"
        :key="index"
        class="hly-home-banner-list"
        @click="toadvertise(el.adHref)"
      >
        <v-img
          :attachment-id="''"
          :default-img="require('@/assets/img/hlwbanner.jpg')"
        />
      </div>
    </a-carousel>
    <div class="hly-home-banner-content">
      <span>工业互联网平台</span>
      <p>致力于以工业场景挖掘价值为您的企业量体裁衣，让工业更有智慧</p>
      <a-button class="btn-red" size="large" @click="consultShow = true">立即咨询</a-button>
    </div>
    <consult-form :show="consultShow" v-model="consultShow"/>
  </div>
</template>

<script>
import { getTenantAds } from "@/api/common";
import { mapGetters } from "vuex";
import VImg from "@/components/VImg";
import consultForm from "@/components/consultForm";
import { getAdvertiseList } from "@/api/advertise";

export default {
  name: "HomeBanner",
  components: { VImg,consultForm },
  data() {
    return {
      adList: [],
      searchName: "",
      bannerInfo: true,
      advertise: [],
      consultShow:false
    };
  },
  computed: {
    ...mapGetters(["tenant"]),
    list() {
      const { adList } = this;
      const arr = [...adList];
      for (let i = 0; i < 1 - adList.length; i++) {
        arr.push({});
      }
      console.log(arr, "arr");
      return arr;
    },
  },
  mounted() {
    this.loadData();
    this.loadGetAdvertise();
    console.log(this.list, "list");
  },
  methods: {
    loadGetAdvertise() {
      let params = {
        adType: "HOME_BANNER",
        validStatus: "1",
      };

      getAdvertiseList(params).then((res) => {
        let data = res.records;
        data.forEach((item) => {
          if (item.validStatus == 1) {
            // this.advertise.push(item);
          }
        });
        if (this.advertise.length == 0) {
          this.advertise = [
            {
              adAttmId: "",
              validStatus: "1",
            },
          ];
        }
      });
    },

    toadvertise(url) {
      if (url) {
        window.open(url);
      }
    },

    bannerafterChange(from, to) {
      if (to == 0) {
        this.bannerInfo = true;
      } else {
        this.bannerInfo = false;
      }
    },
    loadData() {
      const { tenant } = this;
      getTenantAds(tenant.id).then((list) => {
        this.adList = list;
      });
    },
    onSearch(value) {
      if (!value) {
        this.$message.warning("请输入搜索关键词！");
        return;
      }
      this.$router.push(`/searchInfo?val=${value}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.hly-home-banner {
  position: relative;
  height: 480px;
  .hly-home-banner-list {
    height: 480px;
    display: block !important;
    position: relative;
    z-index: 1;
    > img {
      max-width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
  .hly-home-banner-content {
    position: absolute;
    width: 1280px;
    top: 0;
    left: 50%;
    bottom: 0;
    z-index: 2;
    margin-left: -640px;
    padding-top: 92px;
    > span {
      display: block;
      font-size: 38px;
      font-weight: bold;
      color: #333333;
      line-height: 53px;
      margin-bottom: 24px;
    }
    >p{
      font-size: 18px;
      color: #262626;
      line-height: 25px;
      margin-bottom: 56px;
    }
    >button{
      width: 136px;
    }
  }
}
::v-deep .hly-home-banner-dots{
  z-index: 6;
  width: 1280px;
  left: 50%;
  margin-left: -640px;
  text-align: left;
  bottom: 83px;
  >li{
    >button{
      background: #D1D5D8;
      width: 40px;
      opacity: 1;
    }
  }
  .slick-active{
    >button{
      background: #EA0B06 !important;
      width: 40px !important;
    }
  }
}
</style>
<style>
.hly-home-banner-content input {
  font-size: 16px;
  border: 2px solid #ea4243 !important;
  border-color: #ea4243 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.hly-home-banner-content .ant-input-search-button {
  padding: 0 24px;
  background: linear-gradient(90deg, #ea4243 0%, #ff8b4c 100%) !important;
  font-size: 16px;
  color: #fff !important;
  border: none;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>
